.scrollable-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: -2rem;
}

.scroll-items {
    width: 80%;
}

.mountain-list {
    list-style: none;
    padding: 1rem 0 1rem 0;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.arrow {
    top: -3rem;
    cursor: pointer;
    color: #05347a;
    transition: all 0.8s ease;
    padding: 1rem;
}

.arrow:hover {
    color: #0953c2;
}

@media screen and (max-width: 800px) {
    .arrow {
        display: none;
    }

    .scroll-items {
        width: 95%;
    }
}