/* User mountain review */
.user-review-content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.own {
    margin-top: 4rem;
}

.user-mountain-picture {
    width: 90px;
    height: 90px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
}

.user-mountain-header {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
}

.review-information {
    width: 100%;
}

#review-mountain {
    color: #03214f;
    font-size: 20px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: color 0.6s;
    width: 100%;
    font-weight: 450;
}

#review-mountain:hover {
    color: #205097;
}

#review-date {
    font-size: 14px;
    color: #626469;
    margin-top: -0.5rem;
}

#review {
    font-size: 15px;
    color: #626469;
}


hr {
    width: 93%;
    margin-right: 10%;
}

.delete-icon {
    cursor: pointer;
    bottom: 2rem;
    left: 40%;
}

/* Profile content */

.profile-content {
    display: flex;
}

.profile-info-container {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #e9e7e7;
}

.profile-info {
    background-color: #f7f5f5;
    width: 80%;
    border-radius: 15px;
    margin: 2rem 0rem 2rem 0rem;
    box-sizing: border-box;
    padding: 2rem;
    box-shadow: 0 8px 16px 4px rgba(0, 0, 0, 0.25);
}

.profile-photo {
    width: 110px;
    height: 110px;
    background-position: center;
    background-size: cover;
    border-radius: 100px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
}

.icon-settings {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.following-info {
    display: flex;
    margin-top: -1rem;
    gap: 1rem;
}

.logo {
    bottom: 30px;
    right: 40px;
}

.user-reviews {
    top: -10px;
    background-color: #f7f5f5;
    color: #03214f;
    font-size: 25px;
    padding: 0rem 0rem 0rem 1rem;
    font-weight: 450;
}

.profile-settings {
    bottom: 20px;
    cursor: pointer;
    transition: color 0.6s;
}

#user-name {
    font-size: 28px;
    color: #03214f;
    margin-bottom: 2rem;
    font-weight: 450;
}

.user-info-label {
    font-size: 15px;
    color: #626469;
}

.user-info-description {
    color: #03214f;
    font-weight: 450;
}

.profile-reviews {
    width: 60%;
    height: 80vh;
    scroll-behavior: smooth;
    box-sizing: border-box;
    padding: 1rem;
    margin-bottom: 2rem;
}

.user-review-list {
    list-style: none;
    box-sizing: border-box;
    padding: 0 20px;
    height: 70vh;
    overflow-y: scroll;
}

#form-title {
    font-size: 25px;
    color: #03214f;
    font-weight: 450;
}

.edit-form {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.form-label {
    margin-bottom: 0.5rem;
}

.form-select {
    margin-bottom: 1rem;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0.3rem;
    font-family: "Montserrat", sans-serif;
}

#text-input {
    border: 1px solid rgb(140, 139, 139);
}

.user-information-group {
    margin-top: -1.2rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.profile-photo-button {
    position: relative;
    margin: 1rem auto;
    border: 1px solid #476ea9;
    background-color: transparent;
    color: #476ea9;
    box-sizing: border-box;
    font-size: 15px;
    padding: 0.2rem;
    width: 80%;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.profile-photo-button:hover {
    background-color:#476ea9;
    color: #f7f5f5;
}

.profile-photo-notification {
    position: relative;
    margin: 0 auto;
}

.edit-form button {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 1rem;
    border-radius: 10px;
    border-style: none;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.6s;
}

#edit-submit {
    margin-top: 1rem;
    background-color: #205097;
    color: #f7f5f5;
}

#edit-submit:hover {
    background-color: #476ea9;
}

#edit-cancel {
    background-color: #c7c7c9;
}

#edit-cancel:hover {
    background-color: #a0a0a3;
}

#logout {
    width: 100%;
    margin-top: 2rem;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 1rem;
    border-radius: 10px;
    border-style: none;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.6s;
    background-color: #8d8d8d;
}

#logout:hover {
    background-color: #5f5f5f;
}

#empty-review-message {
    text-align: center;
    font-size: 20px;
    padding: 1rem;
    color: #626469;
}

@media screen and (max-width: 767px) {
    .profile-content {
        flex-direction: column;
    }
    
    .profile-info-container {
        width: 100%;
    }

    .profile-reviews {
        width: 98%;
        padding: 0;
    }
}

.profile-footer {
    margin-top: 3rem;
}