@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: "Montserrat", sans-serif;
    background-color: #f7f5f5;
    margin: 0;
}

.carousel-container {
    margin-top: 20px;
    position: relative;
    justify-self: center;
    height: 450px;
    width: 100%;
    overflow: hidden;
}

.carousel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in;
    opacity: 0;
}

.carousel-image.active {
    opacity: 1;
}

.scroll-header {
    font-size: 30px;
    margin-left: 1rem;
    color: #03214f;
    font-weight: 450;
}

.explore-mountains-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 600px;
    margin: 2rem 0 2rem 0;
}

.explore-background {
    width: 90%;
    background-image: url('../../photos/MountainPic.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
}

.explore-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    transition: transform 1.5s ease-out;
}

.explore-background:hover::before {
    transform: scale(1.1);
}

.explore-content {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container {
    width: 100%;
    height: 300px;
    background-color: #03214f;
    color: #f7f5f5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-content {
    width: 90%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#footer-title {
    font-size: 25px;
    font-weight: bold;
}

.footer-content p {
    font-size: 13px;
}

.create-account-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 600px;
    margin: 2rem 0 3rem 0;
}

.container-background {
    width: 90%;
    background-image: url('../../photos/tracks.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
}

.content-container {
    margin-right: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    transition: transform 1.5s ease-out;
}

.container-background:hover::before {
    transform: scale(1.1);
}

.content {
    z-index: 1;
    font-size: 25px;
    color: #0e2d5c;
    margin-bottom: 1.2rem;
}

.create-account-button {
    z-index: 1;
    width: 50%;
    text-align: center;
    padding: 0.7rem 1rem 0.7rem 1rem;
    background-color: #205097;
    color: #f7f5f5;
    border-radius: 10px;
    transition: background-color 0.7s;
    cursor: pointer;
}

.create-account-button:hover {
    background-color: #0f2546;
}

.name-form {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    font-family: "Montserrat", sans-serif;
    background-image: url(../../photos/VerifyBackground.jpg);
    background-size: cover;
    background-position: center;
}

.start-form {
    margin-top: 5rem;
    width: 60%;
    background-color: rgba(234, 234, 234, 0.8);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 8px 16px 4px rgba(0, 0, 0, 0.25);
    padding: 1rem;
}

.start-label {
    margin-bottom: 0.5rem;
}

.start-select {
    border: 1px solid rgb(140, 139, 139);
    margin-bottom: 1rem;
    border-radius: 10px;
    padding: 0.3rem;
    font-family: "Montserrat", sans-serif;
}

.start-form select {
    padding: 0.5rem;
    font-size: 12px;
}

.form-submit {
    width: 100%;
    border-radius: 10px;
    border-style: none;
    padding: 0.7rem;
    cursor: pointer;
    transition: background-color 0.6s;
    background-color: #205097;
    color: #f7f5f5;
}

.form-submit:hover {
    background-color: #476ea9;
}

#form-title {
    color: #f7f5f5;
    font-size: 25px;
}

#verify-form-title {
    color: #f7f5f5;
    font-size: 35px;
    font-weight: 450;
}

.verify-user-file {
    display: none;
}

.verify-user-photo {
    position: relative;
    margin: 0.5rem auto 1.5rem auto;
    border: 1px solid #476ea9;
    background-color: transparent;
    color: #476ea9;
    box-sizing: border-box;
    font-size: 15px;
    padding: 0.4rem;
    width: 90%;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.verify-user-photo:hover {
    background-color:#476ea9;
    color: #f7f5f5;
}

.verify-photo-notification {
    position: relative;
    margin: 0 auto;
}



@media screen and (max-width: 1000px) {
    .content-container {
        margin-right: 4.4rem;
    }
}

@media screen and (max-width: 767px) {
    .explore-content {
        text-align: center;
        margin-top: 4rem;
        margin-left: 2rem;
    }
} 

@media screen and (max-width: 540px) {

    .create-account-container {
        height: 450px;
    }

    .container-background {
        width: 100%;
        border-radius: 0;
    }

    .content-container {
        margin-right: 0.4rem;
    }

    .content {
        font-size: 18px;
    }

    .create-account-button {
        font-size: 11px;
    }

    .explore-mountains-container {
        height: 500px;
    }
    
    .explore-background {
        border-radius: 0;
        width: 100%;
    }

    .footer-container {
        height: 200px;
    }

    .carousel-container {
        height: 350px;
    }
}