@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: "Montserrat", sans-serif;
    background-color: #f7f5f5;
}

/* Region Mountains */
.region-content {
    display: flex;
    width: 100%;
    justify-content: center;
}

.region-list {
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 2rem;
}

/* Mountain page */

.header-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-image: url('../../photos/DiscoverPic.jpg');
    background-size: cover;
    background-position: center;
    height: 450px;
}

.header-sentence {
    color: #0e2d5c;
    font-size: 25px;
    margin-left: 6rem;
}

.search-bar-container {
    position: relative;
    margin: 30px 10px 40px 10px;
    top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-bar {
    width: 80%;
    padding: 1rem 3rem 1rem 3rem;
    border-radius: 20px;
    border: 1px solid #a0a0a3;
    box-shadow: 0 8px 12px 4px rgba(0, 0, 0, 0.25);
}

input {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
}

.search-icon {
    position: absolute;
    left: calc(10% - 65px);
    top: 50%;
    transform: translateY(-50%);
    color: #a0a0a3; 
    pointer-events: none;
}

.search-button {
    position: absolute;
    right: calc(4% + 4%);
    top: 30%;
    color: #476ea9;
    cursor: pointer;
    transition: color 0.6s;
}

.search-button:hover {
    color: #043987;
}

.invisible {
    display: none;
}

.results-container {
    display: flex;
    justify-content: center;
}

.search-results {
    position: relative;
    top: -65px;
    background-color: #d2d6e0;
    width: 80%;
    max-height: 0px;
    overflow: hidden;
    border-radius: 10px;
    transition: max-height 0.3s ease-in;
    display: flex;
}

.active {
    max-height: 1000px;
    margin-bottom: 30px;
}

.map-content .leaflet-container {
    position: relative;
    top: -65px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    border-radius: 20px;
    box-shadow: 0 8px 12px 4px rgba(0, 0, 0, 0.25);
    height: 90vh;
    width: 90%;
    justify-self: center;
    margin: 0 auto;
}


@media screen and (max-width: 767px) {
    .search-button {
        right: calc(4% + 2%)
    }

    .header-sentence {
        margin-left: 2rem;
    }
}

@media screen and (max-width:461px) {
    .search-icon {
        left: calc(10% - 55px);
    }

    .search-button {
        right: calc(4% + 1%)
    }

    .search-bar {
        padding: 1rem 2.5rem 1rem 3rem;
    }

    .map-content .leaflet-container {
        height: 70vh;
    }

}