.bucketlist-section {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    height: 80vh;
    margin-bottom: 3rem;
}

.scroll-title-container {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #f7f5f5;
    color: #03214f;
}

.bucketlist-scroll {
    width: 30%;
    overflow: scroll;
    scroll-behavior: smooth;
}

.scroll-list {
    width: 100%;
}

.bucket-mountains {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    align-items: center;
    margin-right: 2rem;
    scale: 1;
}

.bucketlist-map {
    width: 70%;
    height: 100%;
}

.bucketlist-map .leaflet-container {
    width: 100%;
    height: 100%;
}

#scroll-title {
    margin-left: 2rem;
    font-size: 25px;
}

#bucketlist-empty-message {
    text-align: center;
    font-size: 20px;
    padding: 1rem;
    color: #626469;
}

@media screen and (max-width: 650px) {
    .bucketlist-scroll {
        width: 100%;
    }

    .bucketlist-map, .leaflet-container {
        display: none;
    }
    
}