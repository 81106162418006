.footer-container {
    width: 100%;
    height: 300px;
    background-color: #03214f;
    color: #f7f5f5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-content {
    width: 90%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#footer-title {
    font-size: 25px;
    font-weight: bold;
}

.footer-content p {
    font-size: 13px;
}

@media screen and (max-width: 540px) {
    .footer-container {
        height: 200px;
    }
}